import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import OTPInput from "react-otp-input";
import { ReactComponent as Navigator } from "./assets/google-location-pin.svg";
import "./styles.css";
import axios from "axios";
import { API, valetCode } from "./helpers/AppConstants";
import { convertTime } from "./helpers/formatters";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "./assets/loader.gif";
import { useSearchParams } from "react-router-dom";
import { AppContext } from "./context/AppContext";
import { IoWarning } from "react-icons/io5";

const Content = () => {
  const { storeApiKey, getApiKey } = useContext(AppContext);
  const [entryCode, setEntryCode] = useState("");
  const [exitCode, setExitCode] = useState("");
  const [driverPhoto, setDriverPhoto] = useState(null);
  const [bookingDetails, setBookingDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const apiKey = searchParams.get("api_key");
  const bookingId = searchParams.get("bookingId");
  storeApiKey(apiKey);

  const urlWithoutApiKey = window.location.href.split("&")[0];
  window.history.replaceState({}, document.title, urlWithoutApiKey);

  const getDriverPhoto = async (bookingDetails) => {
    try {
      const response = await axios.get(
        API.getDriverPhoto + bookingDetails.booking.valetTrip.DriverPhoto,
        { responseType: "blob" }
      );
      const imageUrl = URL.createObjectURL(response.data);

      setDriverPhoto(imageUrl);
    } catch (err) {
      setError(err);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(API.getBookingDetails + bookingId);

      setBookingDetails(response.data.data);
      setEntryCode(response.data.data.booking.EntryCode);
      setExitCode(response.data.data.booking.ExitCode);
      getDriverPhoto(response.data.data);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    const apiKey = getApiKey();
    if (apiKey) {
      fetchData();
    } else {
      setLoading(false);
      setError({ message: "Unauthorized Access" });
    }
  }, []);

  const getStartCode = async (id) => {
    const data = { bookingId: id };

    try {
      const result = await axios.put(API.generateStartCode, data);
      if (result.data.statusCode >= 400) {
        toast.error(result.data.data, { position: "top-center" });
      }
      fetchData();
    } catch (error) {
      toast.error(error.data.data, { position: "top-center" });
    }
  };

  const getCompleteCode = async (id) => {
    const data = { bookingId: id };

    try {
      const result = await axios.put(API.generateCompleteCode, data);
      if (result.data.statusCode >= 400) {
        toast.error(result.data.data, { position: "top-center" });
      }
      fetchData();
    } catch (error) {
      toast.error(error.data.data, { position: "top-center" });
    }
  };

  const navigateMap = () => {
    let lat = 0;
    let lng = 0;
    if (bookingDetails.booking.valetTrip.TripType === "PICKUP") {
      lat = bookingDetails.booking.PickupLatitude;
      lng = bookingDetails.booking.PickupLongitude;
    } else {
      lat = bookingDetails.booking.DropLatitude;
      lng = bookingDetails.booking.DropLongitude;
    }
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&travelmode=driving`,
      "_blank"
    );
  };

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "80vh" }}
      >
        <img src={loader} width={100} height={100} alt="Loading..." />
      </div>
    );
  if (error)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "80vh" }}
      >
        <div className="text-center">
          <IoWarning color="#BA0021" size={100} />
          <p style={{ color: "#BA0021" }}>
            <strong>Error:</strong> {error.message}
          </p>
        </div>
      </div>
    );

  return (
    <Container className="mt-4 mb-4 container">
      <ToastContainer />
      <Row>
        <Col md={3}></Col>
        <Col md={6} className="mx-2">
          {/* <IoIosArrowBack size={25} /> */}
          <h4 className="fw-bolder text-center mb-4">
            Booking #{bookingDetails.booking.BookingRefNo}
          </h4>

          <Row className="mb-4">
            <Col>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h5 className="fw-bolder">Valet Details</h5>
                <p className="red-btn"></p>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <MdLocationPin size={20} color="black" />
                  <p className="ml-2">
                    {bookingDetails.booking.valetTrip.TripType === "PICKUP"
                      ? bookingDetails.booking.PickupAddress
                      : bookingDetails.booking.DropAddress}
                  </p>
                </div>
                <p className="red-btn"></p>
              </div>
            </Col>
          </Row>

          <h6>
            {bookingDetails.booking.valetTrip.TripType === "PICKUP"
              ? "Pick Up"
              : "Drop"}
          </h6>
          <Row className="d-flex align-items-center">
            <Col xs={9}>
              <div className="d-flex align-items-center">
                {driverPhoto ? (
                  <img
                    width={70}
                    height={70}
                    src={driverPhoto}
                    alt="Driver Pic"
                    className="rounded-circle"
                  />
                ) : (
                  <FaUser size={60} className="bg-light rounded-circle p-2" />
                )}

                <div className="ml-2">
                  <h6>{bookingDetails.booking.valetTrip.DriverName}</h6>
                  <p>
                    {valetCode[bookingDetails.booking.valetTrip.TripStatus]}
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={3}>
              <div className="d-flex justify-content-center">
                <div className="rounded-circle p-2 callBg border border-dark">
                  <a
                    href={`tel:+91${bookingDetails.booking.valetTrip.DriverMobileNo}`}
                  >
                    <BiSolidPhoneCall color="black" size={20} />
                  </a>
                </div>
              </div>
            </Col>
          </Row>

          <hr />

          <Row className="mb-3">
            <Col xs={7} md={8}>
              <OTPInput
                value={entryCode}
                onChange={setEntryCode}
                numInputs={4}
                renderSeparator={<span> </span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  width: "35px",
                  height: "35px",
                  backgroundColor: entryCode ? "#286ef0" : "gray",
                  color: "white",
                  outline: "none",
                  border: "none",
                  borderRadius: 5,
                  marginRight: 5,
                }}
              />
            </Col>
            <Col xs={5} md={4}>
              <div
                className="entryExitBtn py-1 px-2 rounded border border-dark"
                onClick={() => getStartCode(bookingDetails.booking.id)}
              >
                <p className="entryExitBtnTxt text-center">START</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={7} md={8}>
              <OTPInput
                value={exitCode}
                onChange={setExitCode}
                numInputs={4}
                renderSeparator={<span>{"  "}</span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  width: "35px",
                  height: "35px",
                  backgroundColor: exitCode ? "#286ef0" : "gray",
                  color: "white",
                  outline: "none",
                  border: "none",
                  borderRadius: 5,
                  marginRight: 5,
                }}
              />
            </Col>
            <Col xs={5} md={4}>
              <div
                className="entryExitBtn py-1 px-2 rounded border border-dark"
                onClick={() => getCompleteCode(bookingDetails.booking.id)}
              >
                <p className="entryExitBtnTxt">COMPLETE</p>
              </div>
            </Col>
          </Row>

          <hr />

          <div className="mt-3 mb-3 d-flex justify-content-around">
            <div>
              <p className="fw-bold">{bookingDetails.parkingSlot.SlotName}</p>
              <p>{bookingDetails.vehicle.RegistrationNo}</p>
              {bookingDetails.booking.BookingStatus === "COMPLETE" ? (
                <p className="complete-text">Completed</p>
              ) : bookingDetails.booking.BookingStatus === "CANCELLED" ? (
                <p className="cancelled-text">Cancelled</p>
              ) : (
                <p>{bookingDetails.booking.BookingStatus}</p>
              )}
            </div>
            <div>
              <p>{bookingDetails.booking.BookingDate.replaceAll("/", " / ")}</p>
              <p>
                {convertTime(bookingDetails.booking.FromTime)} -{" "}
                {convertTime(bookingDetails.booking.ToTime)}
              </p>
            </div>
          </div>
          <div className="mb-4 navigator" onClick={navigateMap}>
            <div className="d-flex justify-content-between align-items-center">
              <Navigator width={30} height={30} />
              <span>Navigate using Google Maps</span>
              <IoIosArrowForward size={20} />
            </div>
          </div>
        </Col>
        <Col md={3}></Col>
      </Row>
    </Container>
  );
};

export default Content;
