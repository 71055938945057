export const convertTime = (time24) => {
  let [hours, minutes] = time24.split(":");

  hours = parseInt(hours, 10);

  const period = hours >= 12 ? "PM" : "AM";

  hours = hours % 12 || 12;

  return `${hours}:${minutes} ${period}`;
};

export const convertDate = (isoString) => {
  const date = new Date(isoString);

  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay} / ${formattedMonth} / ${year}`;
};
