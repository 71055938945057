import axios from "axios";
import React, { createContext, useState, useEffect, useContext } from "react";

const AppContext = createContext();

// Custom hook to use the ScreenSizeContext
export const useScreenSize = () => useContext(AppContext);

// Provider component
const AppProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const storeApiKey = (apiKey) => {
    if (apiKey) {
      sessionStorage.setItem("apiKey", apiKey);

      // Set the API key in Axios headers for future requests
      axios.defaults.headers["Authorization"] = apiKey;
    }
  };

  const getApiKey = () => {
    const key = sessionStorage.getItem("apiKey");

    if (key) {
      axios.defaults.headers["Authorization"] = key;
      return key;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <AppContext.Provider value={{ isMobile, storeApiKey, getApiKey }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
