import React from "react";
import Content from "./Content";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

const App = () => {
  return (
    <>
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<Content />} />
        </Routes>
      </Router>
      <Footer />
    </>
  );
};

export default App;
