// Footer.js
import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaPhone } from "react-icons/fa";
import "./Footer.css"; // Custom CSS for footer styling
import { useScreenSize } from "../../context/AppContext";

const Footer = () => {
  const { isMobile } = useScreenSize();

  function renderFooter() {
    return (
      <div className="bg-light border-top py-3 text-center">
        <FaPhone className="me-2 mr-1" />
        <a href="tel:+919152640740" className="text-dark text-decoration-none">
          +91 9152640740
        </a>
      </div>
    );
  }

  if (isMobile) {
    return renderFooter();
  }

  return (
    <Row className="footer">
      <Col md={3}>
        {/* <FaQuestionCircle className="me-2 mr-1" />
            Help & Support */}
      </Col>
      <Col md={6}>{renderFooter()}</Col>
      <Col md={3}>
        {/* <FaEnvelope className="me-2 mr-1" />
            <a
              href="mailto:contactus@kwikpark.io"
              className="text-dark text-decoration-none"
            >
              contactus@kwikpark.io
            </a> */}
      </Col>
    </Row>
  );
};

export default Footer;
