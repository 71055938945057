const baseUrl = process.env.REACT_APP_BASE_URL;

export const valetCode = {
  PKNOTSTRTD: "Pickup Not Started",
  PKONTHEWAY: "Pickup: Driver On The Way",
  PKREACHED: "Pickup: Driver Reached Pickup Point",
  PKCARPICK: "Pickup: Car Picked",
  PKCOMPLETE: "Pickup Complete",
  DRNOTSTRTD: "Drop Not Started",
  DRONTHEWAY: "Drop: Driver On The Way",
  DRREACHED: "Drop: Driver Reached Drop Off Point",
  DRCOMPLETE: "Drop Complete",
};

export const API = {
  getBookingDetails:
    baseUrl + "/integration/booking/getBookingDetails?bookingId=",
  generateStartCode: baseUrl + "/integration/booking/getStartCode",
  generateCompleteCode: baseUrl + "/integration/booking/getCompleteCode",
  getDriverPhoto: baseUrl + "/integration/booking/getDriverPhoto?id=",
  checkPendingPayments: baseUrl + "/integration/booking/checkPendingPayment",
};
