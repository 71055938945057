import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Header.css";
import KPLogo from "../../assets/logo.png";
import AwfisLogo from "../../assets/awfis-logo.png";
import { useScreenSize } from "../../context/AppContext";

const Header = () => {
  const { isMobile } = useScreenSize();

  function renderHeader() {
    return (
      <header className="bg-light py-2 py-m-3 border-bottom">
        <div className="d-flex justify-content-between align-items-center px-5">
          <img
            src={AwfisLogo}
            alt="Company 2 Logo"
            width={isMobile ? 100 : 150}
            height={isMobile ? 35 : 50}
          />
          <div className="d-flex align-items-end position-relative">
            <small style={{ position: "absolute", bottom: 0, left: -65 }}>
              powered by
            </small>
            <img
              className="ms-auto"
              src={KPLogo}
              alt="Company 1 Logo"
              width={isMobile ? 100 : 150}
              height={isMobile ? 35 : 50}
            />
          </div>
        </div>
      </header>
    );
  }

  if (isMobile) {
    return <>{renderHeader()}</>;
  }

  return (
    <Row>
      <Col xs={0} md={3}></Col>
      <Col xs={12} md={6}>
        {renderHeader()}
      </Col>
      <Col xs={0} md={3}></Col>
    </Row>
  );
};

export default Header;
